<template>
  <div class="login-wrapper">
    <div class="card login-form">
      <div class="card-body py-5 px-4">
        <div class="logo mb-5">
          <img src="../../assets/logo.svg" alt="">
        </div>
        <form @submit="checkForm">
          <p v-if="errors.length">
            <b>Verificar o(s) seguinte(s) campo(s):</b>
            <ul>
              <li v-for="error, index in errors" :key="index">{{ error }}</li>
            </ul>
          </p>
          <div class="form-group">
            <label for="email">E-mail</label>
            <input id="email" type="email" class="form-control" v-model="form.email" aria-describedby="emailHelp">
          </div>
          <button type="submit" class="btn btn-primary btn-block mb3">
            Solicitar nova senha
          </button>

          <router-link class="btn btn-block" to="/">
            Cancelar
          </router-link>
        </form>
      </div>
    </div>
  </div>
</template>

.<style lang="scss" scoped>
  .login-wrapper {
    display: flex;
    margin: 0 10%;
    height: 100%;
    width: 350px;
  }

  .login-form {
    width: 100%;
    margin: auto;
  }

  .logo {
    width: 60%;
    margin: 0 auto;

    >img {
      width: 100%;
    }
  }
</style>

<script>
  import firebase from "firebase";

  export default {
    data() {
      return {
        errors: [],
        form: {},
      };
    },
    methods: {
      checkForm: async function (e) {
        
        if (this.form.email && this.form.password) {
          return true;
        }

        this.errors = [];

        if (!this.form.email) {
          this.errors.push("Campo E-mail é obrigatório.");
        }

        console.log(this.errors);

        if(this.errors.length == 0){
          var auth = firebase.auth();
          console.log(this.form);
          auth.sendPasswordResetEmail(this.form.email).then(() => {
            console.log("email enviado ")
            this.errors.push("um mensagem foi enviado ao seu email com as instruções de acesso a plataforma");
          }).catch((error) => {
            this.errors.push(error.message);
          });
        }

        e.preventDefault();
      }
    },
  };
</script>